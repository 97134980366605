import React from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { WebConstant } from "../../../api/ApiConstants";

function ActiveGoalTitle({ activeGoalTitle }) {
  const navigate = useNavigate();

  // console.log("krishna     =   ", activeGoalTitle?.goalTitle);

  return (
    <div>
      <div className="flex justify-center my-5">
        <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex flex-col md:flex-row justify-between px-5  tablet:items-center">
          <div className="text-gray-700 w-full md:w-[50%] mb-5 md:mb-0">
            <p className=" text-xl md:text-4xl font-bold  mb-3">
              Crack {activeGoalTitle?.goalTitle} with {WebConstant?.WEB_NAME}
            </p>
            <p className="font-semibold text-sm">
              Over <span className="text-green-500">10 crore</span> learners
              trust us for online and offline coaching
            </p>
          </div>
          <div className="  lg:w-[50%] flex flex-col lg:flex-row items-center lg:items-start">
            <CustomButton
              lable={`Try learning for free`}
              className={`py-3  tablet:!px-5  my-1 lg:my-0 w-full !mx-0 lg:!mx-3`}
              onClick={() => navigate("/")}
            />
            <CustomButton
              lable={`View subscription plans`}
              className={`bg-green-500 text-white border-green-500 py-3  tablet:!px-5 my-1 lg:my-0 w-full !mx-0 `}
              onClick={() => navigate("/plan")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    activeGoalTitle: state?.userSelectGoalReducer?.userGoalInfo?.activeGoal,
    loading: state?.userSelectGoalReducer?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveGoalTitle);
